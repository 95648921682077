<template>
  <page-container class="education-page-container" style="max-width: 680px;margin: auto">
    <div class="title">Программа (техническое описание)</div>
    <div v-if="games.length===1 && games[0].description" v-html="games[0].description"></div>
    <template v-else>
      Программа электронных материалов сайта описывает стоимость, структуру и комплектацию предоставляемых
      онлайн-продуктов, технические особенности использования онлайн-продукта
      <br><br>
      <br><br>ЭЛЕКТРОННАЯ ВЕРСИЯ ИГР ПРЕДНАЗНАЧЕНА ДЛЯ ГРУППОВОЙ И ИНДИВИДУАЛЬНОЙ РАБОТЫ, ОСОБЕННОСТИ И МЕТОДИЧЕСКИЕ
      РЕКОМЕНДАЦИИ КОТОРОЙ ОПИСАНЫ В ИНСТРУКЦИЯХ ОФФЛАЙН-ВЕРСИЙ.
      <br><br>
      ВНИМАНИЕ! ОНЛАЙН-ВЕРСИЯ ЯВЛЯЕТСЯ ДОПОЛНЕНИЕМ К ОФФЛАЙН-ВЕРСИИ. ИНСТРУКЦИЯ ВХОДИТ В КОМПЛЕКТАЦИЮ ТОЛЬКО В ТАРИФЕ
      "МАКСИМАЛЬНЫЙ" И ОТДЕЛЬНО НЕ
      ПРОДАЁТСЯ!
      <br><br>Базовая версия игры представляет такие возможности
      красивый дизайн, удобное интуитивное управление, все колоды игры, отличное качество изображений, перемещение фишек
      по полю, случайный выбор карт, увеличение карт, функции - перевернуть карты лицом или рубашкой верх, перемешать
      колоду, 3d - кубики. Открывает карты и перемещает фишки ведущий, игроки видят игру при помощи функции
      "демонстрация
      экрана" в программах для организации видео-конференций (Zoom, Skype и т.п.). Ведущий заходит на платформу через
      личный кабинет. Онлайн-платформа прекрасно работает и с компьютера и с планшета. Сами игроки могут пользоваться
      функциями кубиков игры, сами бросать виртуальный кубик в соответствующем разделе сайта.
      <br><br>Стоимость базовой версии 2500 в год для одной игры.
      <br><br>Продвинутая версия игры представляет такие возможности
      красивый дизайн, удобное интуитивное управление, все колоды игры, отличное качество изображений, перемещение фишек
      по полю, случайный выбор карт, увеличение карт, функции - перевернуть карты лицом или рубашкой верх, перемешать
      колоду, 3d - кубики. Открывать карты и перемещать фишки может и ведущий и игроки. Сама общение игроков может
      проходить в программах для организации видео-конференций (Zoom, Skype и т.п.). Ведущий заходит на платформу через
      личный кабинет, далее он формирует ссылки на игру для игроков, по которым они получают временный доступ к
      материалам
      игры на время, ограниченное игрой. Онлайн-платформа прекрасно работает и с компьютера и с планшета. Сами игроки
      могут пользоваться функциями кубиков игры, сами бросать виртуальный кубик в соответствующем разделе сайта.
      <br><br>Стоимость продвинутой версии 5000 в год для одной игры.
      <br><br>
      Максимальная версия игры представляет такие возможности красивый дизайн, удобное интуитивное управление, все
      колоды игры, отличное качество изображений, перемещение фишек по полю, случайный выбор карт, увеличение карт,
      функции - перевернуть карты лицом или рубашкой верх, перемешать колоду, 3d - кубики. Открывать карты и перемещать
      фишки может и ведущий и игроки. Сама общение игроков может проходить в программах для организации
      видео-конференций (Zoom, Skype и т.п.). Ведущий заходит на платформу через личный кабинет, далее он формирует
      ссылки на игру для игроков, по которым они получают временный доступ к материалам игры на время, ограниченное
      игрой. Онлайн-платформа прекрасно работает и с компьютера и с планшета. Сами игроки могут пользоваться функциями
      кубиков игры, сами бросать виртуальный кубик в соответствующем разделе сайта.
      Также в версию входит инструкция игры в электронном виде.
      <br><br>

      Стоимость максимальной версии 6000 в год для одной игры.

      <br><br>
      Также можно воспользоваться опцией - убрать поле. Тогда справа будут карты и карточки, а слева однотонный фон и
      онлайн-версию можно использовать для индивидуальных консультаций при помощи метафорических карт игры.
      <br><br>
      Комплектация игр:
      <GameEquipment style="margin-bottom: 20px" v-for="game in games.filter(g=>+g.price)" :game="game" :key="game.id"/>
    </template>
  </page-container>
</template>

<script>
import PageContainer from "@/components/PageContainer";
import GameEquipment from "@/pages/psycho/GameEquipment";

export default {
  name: "Program",
  components: {GameEquipment, PageContainer},
  data() {
    return {
      games: [],
    }
  },
  methods: {
    load() {
      this.$server.request('game2/get', {with_price: 1, id: this.$route.params.id}, data => this.games = data.response);
    }
  },
  mounted() {
    this.load();
  },
  watch: {
    '$route.params.id'() {
      this.load();
    }
  }
}
</script>

<style scoped>

</style>

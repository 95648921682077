<template>
  <div>
    <div class="title">Контакты</div>
    <div style="text-align: center">
      <p>
        Почта: <a href="mailto:annasudneko@bk.ru">annasudneko@bk.ru</a>
      </p>
      <p>
        Телефон: <a href="tel:+7 (926) 178-25-25">+7 (926) 178-25-25</a>
      </p>
      <p>
        Сайт: <a target="_blank" href="https://sand4self.ru">Sand4self.ru</a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Contacts",
}
</script>

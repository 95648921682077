<template>
  <page-container style="max-width: 900px">
    <div class="title">Навигация</div>
    <p>ШАГ 1.</p>
    <p>Ознакомьтесь с офертой, пользовательским соглашением и программой необходимой вам игры.</p>
    <p>ШАГ 2.</p>
    <p>Оплата. Внимание! При оплате указывайте тот email, которым будете пользоваться для входа на платформу.</p>
    <p>Если вы покупали игру через другой магазин, время подключения может занять сутки.&nbsp;</p>
    <p>ШАГ 3.</p>
    <p>Введите свой email в личном кабинете (верхний правый угол, при нажатии появится окошко с надписью &quot;авторизация&quot;) и нажмите &quot;получить код&quot;</p>
    <p>ШАГ 4.</p>
    <p>Откройте вашу электронную почту, получите код в письме</p>
    <p>ШАГ 5.&nbsp;</p>
    <p>Введите проверочный код в окне авторизации</p>
    <p>ШАГ 6.</p>
    <p>Зайдите в раздел &quot;Т-игры&quot; или &quot;Магазин&quot;, выберете купленную игру и нажмите &quot;Новый сеанс&quot;</p>
    <p>ШАГ 7.</p>
    <p>Для тарифа &quot;продвинутый&quot; можно воспользоваться опцией &quot;ссылка на игру&quot;. Вы заходите в игру и копируете ссылку наверху, которую можно послать участникам для доступа в игру.</p>
    <p><br></p>
    <p>Внимание!</p>
    <p>Ссылка работает только когда ведущий находится в игре. Ограничения по количеству игроков - 8 человек по ссылке.</p>
    <p>Ссылка действует 3 часа, если игра не закончилась, сформируйте новую ссылку. Для того, чтобы вернуться к полю с уже выложенными фишками и картами, нажмите &quot;предыдущий сеанс&quot;. У каждого игрока сохраняется свой &nbsp;индивидуальный сеанс.</p>
    <Contacts/>
  </page-container>
</template>

<script>
import PageContainer from "@/components/PageContainer";
import Contacts from "@/components/Contacts";
export default {
  name: "Instructions",
  components: {Contacts, PageContainer},
  mounted() {
    document.getElementById('app').classList.add('hide_bg');
  },
  beforeUnmount() {
    document.getElementById('app').classList.remove('hide_bg');
  }
}
</script>

<style scoped>

</style>

<template>
  <page-container style="max-width: 600px">
    <div v-if="course===null"> Загрузка...</div>
    <div v-else>
      <div v-if="course.id" class="title" style="text-align: left">
        <router-link to="/adminka/Курсы">Все курсы</router-link>
        > Курс "{{ course.title }}"
      </div>
      <div v-else class="title" style="text-align: left">
        <router-link to="/adminka/Курсы">Все курсы</router-link>
        > Новый курс
      </div>
      <div style="display: flex;flex-direction: row;gap: 20px">
        <div class="fields">
          <div @click="$refs.fileInput.click()" class="image"
               :style="`background-image:url(${course.preview || '/img/empty_image.png'});${course.preview?'background-size: cover;':''}`">
            <input @change="updatePreview" ref="fileInput" type="file" style="display: none">
          </div>
          <div class="field">
            <label>Название курса</label>
            <input v-model="course.title"/>
          </div>
          <div class="field">
            <label>Стоимость</label>
            <input v-model="course.price"/>
          </div>
          <div class="field">
            <label>Описание</label>
            <textarea rows="3" v-model="course.about"/>
          </div>
          <div class="field" style="flex-direction: row;gap: 10px">
            <label for="published">Опубликован</label>
            <input id="published" style="width: 16px;height: 16px;" type="checkbox" v-model="course.published"/>
          </div>
        </div>
        <div class="videos">
          <div class="video" v-for="(video, index) in course.videos" :key="video.id">
            <div class="fields">
              <div>Урок № {{ index + 1 }} <span style="float: right;color: red;cursor: pointer" @click="removeVideo(index)">Удалить</span>
              </div>
              <div class="field">
                <label>Название урока</label>
                <input v-model="video.title"/>
              </div>
              <div class="field">
                <LargeFileInput @file-selected="file=>video.title = video.title || file.name.split('.').slice(0,-1).join('.')"
                                :value="video.path" @change="val=>video.path=val"/>
              </div>
            </div>
          </div>
          <AddBlock style="height: 168px" size="300" @click="addVideo" title="+ Добавить видеоурок"/>
        </div>
      </div>
      <Button style="margin-top: 20px" @click="save" text="Сохранить"/>
    </div>
  </page-container>
</template>

<script>
import Button from "@/components/Button";
import AddBlock from "@/components/AddBlock";
import LargeFileInput from "@/components/LargeFileInput";

export default {
  name: "CourseEdit",
  components: {LargeFileInput, AddBlock, Button},
  data() {
    return {
      course: null,
    }
  },
  methods: {
    load() {
      if (+this.$route.params.id) {
        this.$server.request('course/get/' + this.$route.params.id, {}, data => {
          data.response.published = !!(+data.response.published)
          this.course = data.response
        });
      } else {
        this.course = {id: this.$route.params.id, videos: []};
        // this.addVideo();
      }
    },
    removeVideo(index) {
      if ((this.course.videos[index].title || this.course.videos[index].path) && !confirm('Удалить?')) return;
      this.course.videos.splice(index, 1);
    },
    addVideo() {
      this.course.videos.push({id: 'new_' + this.course.videos.length})
    },
    updatePreview() {
      if (this.$refs.fileInput.files[0]) this.$server.request('auth/uploadFile', {file: this.$refs.fileInput.files[0]}, data => {
        this.course.preview = data.response;
      })
    },
    save() {
      // return console.log(this.course);
      this.$server.request('course/' + (this.course.id ? 'update/' + this.course.id : 'create'), this.course, () => this.$router.push('/adminka/Курсы'));
      // this.$server.request('course/' + (this.course.id ? 'update/' + this.course.id : 'create'), this.course);
    }
  },
  mounted() {
    this.load();
  }
}
</script>
<style lang="scss">
.fields {
  min-width: 300px;

  .image {
    max-width: 300px;
    height: 300px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 50%;
    cursor: pointer;
    border: 2px dashed var(--primary-text-color);
    border-radius: 8px;

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}

.videos {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 20px;
}

.video {
  border: 2px solid var(--primary-text-color);
  padding: 10px;
  border-radius: 8px;
  height: fit-content;

  .field {
    width: 280px;
  }

  .fields {
    min-width: 200px;
  }
}
</style>

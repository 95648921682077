<template>
  <page-container class="contacts-page-container" style="background: rgba(255, 255, 255, 0.5)">
    <div class="title">Админка</div>
    <div style="display: flex;gap: 10px;margin-bottom: 20px">
      <router-link :to="`/adminka/${tab}`" class="button" v-for="tab in tabs"
                   :class="{'router-link-active': tab===$route.params.tab}" :key="tab">
        {{ tab }}
      </router-link>
    </div>
    <div v-if="$route.params.tab===tabs[3]">
      <div class="controls">
        <input type="text" v-model="searchPromo" placeholder="Поиск по промокоду"/>
        <div style="display: flex;align-items: center;gap: 10px;">
          <label for="searchActivePromo">Только действующие</label>
          <input id="searchActivePromo" type="checkbox" v-model="searchActivePromo">
        </div>
        <Button style="width: fit-content" @click="$refs.PromoModal.open(0)" text="+ Создать промокод"/>
        <PromoModal ref="PromoModal"/>
      </div>
      <table class="adminTable">
        <thead>
        <th>Промокод</th>
        <th>Скидка</th>
        <th>Срок</th>
        <th>Одноразовый</th>
        <th>Действует</th>
        <th></th>
        </thead>
        <tbody>
        <tr v-for="promo in promocodes.filter(p=>(!searchPromo.trim() || p.code.indexOf(searchPromo.trim())>-1) && (!searchActivePromo || !(+p.deleted)))"
            :key="promo.id">
          <td>{{ promo.code }}</td>
          <td>{{ promo.percent }}%</td>
          <td>С {{ promo.date_from || '---' }} по {{ promo.date_to || '---' }}</td>
          <td>{{ +promo.one_time ? 'Да' : 'Нет' }}</td>
          <td>{{ +promo.deleted ? 'Нет' : 'Да' }}</td>
          <td>
            <div style="display: flex;gap: 5px">
              <Button class="small" @click="$refs.PromoModal.open(promo.id)" text="Редактировать"/>
              <Button v-if="+promo.deleted" class="small" @click="activatePromo(promo.id)" text="Включить"/>
              <Button v-else class="small" @click="removePromo(promo.id)" text="Выключить"/>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <ProductTable v-if="$route.params.tab===tabs[4]"/>
    <div v-if="$route.params.tab===tabs[1]">
      <div class="controls">
        <div>Период: с <input v-model="authorsPeriod.from" type="date"> по <input v-model="authorsPeriod.to"
                                                                                  type="date">
        </div>
        <Button @click="load" text="Выбрать"/>
      </div>
      <table class="adminTable">
        <thead>
        <tr>
          <th>№</th>
          <th>Автор</th>
          <th>Выручка</th>
          <th>Комиссия робокассы ({{ authorPaymentParams.paymentFee }}%)</th>
          <th>Выплата автору ({{ authorPaymentParams.authorPercent }}%)</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="author in authors" :key="author.id">
          <td>{{ author.id }}</td>
          <td>{{ author.name }} <span @click="$refs.AuthorModal.open(author.id)" style="text-decoration: underline;cursor: pointer">Ред</span></td>
          <td>{{ author.revenue }} ₽</td>
          <td>{{ author.robokassaFee }} ₽</td>
          <td>{{ author.toPay }} ₽</td>
        </tr>
        </tbody>
      </table>
      <AuthorModal @update="load" ref="AuthorModal"/>
    </div>
    <div v-if="$route.params.tab===tabs[0]">
      <div class="controls">
        <input type="text" v-model="search" placeholder="Поиск по email..."/>
        <input v-model="email" placeholder="Добавить email"/>
        <Button @click.native="users = [{email, decks:[], games: [], courses: []}].concat(users);email = ''"
                text="Добавить"/>
      </div>
      <div class="user"
           v-for="user in users.filter(u=>(!search || u.email.toLowerCase().indexOf(search.toLowerCase())!==-1))"
           :key="user.id"
      >
        <div style="width: 300px">
          <div>
            <div>{{ user.email }}</div>
            <div>Фиксированный код для входа: {{ user.code }}
              <Button @click="user.code=Math.ceil(Math.random()*(999999-100000))+100000"
                      style="width: fit-content;display: inline-block;margin-right: 20px" color="white"
                      text_color="var(--primary-text-color)" text="Сгенерировать"/>
              <Button @click="user.code=''" style="width: fit-content;display: inline-block" color="white"
                      text_color="var(--primary-text-color)" text="Убрать"/>
            </div>
            <div style="cursor: pointer;margin-top: 20px" @click="user.admin = +user.admin? 0:1">
              <template v-if="+user.admin">✅</template>
              Админ
              <div style="font-size: 12px;color: #333">доступ ко всем играм, колодам, админке</div>
            </div>
            <div style="font-size: 14px;color: #333;margin-top: 20px">Напротив игр и колод есть дата окончания срока
              доступа (включительно). Если её не указать - доступ будет бессрочный. Если указана прошедшая дата -
              доступа
              у пользователя
              не будет
            </div>
            <Button style="width: 100px;margin-top: 20px" @click.native="save(user)" text="Сохранить"/>
          </div>
        </div>
        <div style="flex: 1;">
          <div style="font-weight: bold;font-size: 22px">Игры</div>
          <div class="list-item" v-for="game in games" :key="game.id">
          <span @click="toggleItem(user, 'games', game.id)"><template v-if="user.games[game.id]">✅</template>
          {{ game.title }}
          </span>
            <div style="display: flex;gap: 10px;" v-if="user.games[game.id]">
            <span v-for="(option_title, option_name) in dataOptions" :key="option_name"
                  style="display: flex;align-items: center;gap: 5px;">
            <label style="" :for="`checkbox_${option_title}_${game.id}`">{{ option_title }}</label>
            <input :id="`checkbox_${option_title}_${game.id}`" type="checkbox"
                   v-model="user.games[game.id].data[option_name]">
              </span>
              <input type="date" style="" v-model="user.games[game.id].date_to"/>
            </div>
          </div>
        </div>
        <div style="flex: 1">
          <div style="font-weight: bold;font-size: 22px">Колоды</div>
          <div class="list-item" v-for="deck in decks" :key="deck.id">
          <span @click="toggleItem(user, 'decks', deck.id)"><template v-if="user.decks[deck.id]">✅</template>{{ deck.title }}</span>
            <input v-if="user.decks[deck.id]" type="date" style=""
                   v-model="user.decks[deck.id].date_to"/>
          </div>
        </div>
        <div style="flex: 1" v-if="user.courses">
          <div style="font-weight: bold;font-size: 22px">Курсы</div>
          <div class="list-item" v-for="course in courses" :key="course.id">
          <span @click="toggleItem(user, 'courses', course.id)"><template v-if="user.courses[course.id]">✅</template>
          {{ course.title }}</span>
            <input v-if="user.courses[course.id]" type="date" style=""
                   v-model="user.courses[course.id].date_to"/>
          </div>
        </div>
        <div v-else>{{ user }}</div>
      </div>
    </div>
    <div v-if="$route.params.tab===tabs[2]">
      <div>Занято на диске {{ diskUsage }}. Очищение неиспользуемых видеофайлов происходит каждый день в 5 утра.
        Удаляются только те, что неиспользованы в курсах и загружены были более 12 часов назад.
      </div>
      <div style="margin-top: 20px;
    display: flex;
    gap: 20px;flex-wrap: wrap">
        <AddBlock title="+ Добавить курс" @click="$router.push('/courses/new')"/>
        <router-link v-for="course in courses" :key="course.id" :to="`/courses/${course.id}/edit`">
          <div style="width: 300px;border-radius: 8px;overflow: hidden;background: white;">
            <div style="height: 300px;background-repeat: no-repeat;
    background-position: center;background-color: #eee;
    background-size: 50%;"
                 :style="`background-image:url(${course.preview || '/img/empty_image.png'});${course.preview?'background-size: cover;':''}`"></div>
            <div style="padding: 5px 10px;">{{ course.title }}</div>
          </div>
        </router-link>
      </div>
    </div>
  </page-container>
</template>

<script>
import Button from "@/components/Button";
import AddBlock from "@/components/AddBlock";
import PromoModal from "@/pages/PromoModal";
import ProductTable from "@/pages/admin/product/Table.vue";
import AuthorModal from "@/pages/admin/author/Edit.vue";

export default {
  name: "Admin",
  components: {AuthorModal, ProductTable, PromoModal, AddBlock, Button},
  data() {
    let tabs = ['Клиенты', 'Авторы', 'Курсы', 'Промокоды', 'Товары'];
    let currentTab = this.$route.params.tab || tabs[0];
    return {
      users: [],
      email: '',
      diskUsage: '',
      search: '',
      searchPromo: '',
      searchActivePromo: true,
      decks: [],
      games: [],
      courses: [],
      products: [],
      promocodes: [],
      authors: [],
      authorPaymentParams: {},
      authorsPeriod: {
        // from: this.$moment().add(-1, 'month').startOf('month').format('YYYY-MM-DD'),
        // to: this.$moment().add(-1, 'month').endOf('month').format('YYYY-MM-DD'),
        from: this.$moment().startOf('year').format('YYYY-MM-DD'),
        to: this.$moment().endOf('year').format('YYYY-MM-DD'),
      },
      dataOptions: {},
      tabs,
      currentTab,
    }
  },
  watch: {
    '$route.params.tab'(value) {
      this.load();
    }
  },
  methods: {
    toggleItem(user, item, id) {
      if (user[item][id]) {
        delete (user[item][id]);
      } else {
        user[item][id] = {date_to: '', data: {}};
        if (item === 'games') {
          let game = this.games.find(g => g.id == id);
          let decks = game.decks_buy.split(',');
          decks.forEach(d => {
            user.decks[d] = {date_to: '', data: {}}
          });
        }
      }
    },
    save(user) {
      this.$server.request('admin/save', {user: JSON.stringify(user)}, () => {
        location.reload();
      });
    },
    removePromo(id) {
      this.$server.request('promo/remove/' + id, {}, () => {
        this.$eventBus.emit('promo-updated');
      });
    },
    activatePromo(id) {
      this.$server.request('promo/update/' + id, {deleted: 0}, () => {
        this.$eventBus.emit('promo-updated');
      });
    },
    load() {
      if (this.$route.params.tab === this.tabs[0]) {
        this.$server.request('admin/get', {}, (data) => {
          this.users = data.response;
          this.decks = data.decks;
          this.games = data.games;
          this.courses = data.courses;
          this.dataOptions = data.dataOptions;
        })
      } else if (this.$route.params.tab === this.tabs[1]) {
        this.$server.request('admin/getAuthors', this.authorsPeriod, (data) => {
          this.authors = data.response;
          this.authorPaymentParams = data.params;
        });
      } else if (this.$route.params.tab === this.tabs[2]) {
        this.$server.request('course/get', {}, (data) => {
          this.courses = data.response;
          this.diskUsage = data.diskUsage;
        });
      } else if (this.$route.params.tab === this.tabs[3]) {
        this.$server.request('promo/get', {}, (data) => {
          this.promocodes = data.response;
        });
      } else if (this.$route.params.tab === this.tabs[4]) {
        this.$server.request('product/get', {}, (data) => {
          this.products = data.response;
        });
      }
    }
  },
  mounted() {
    document.getElementById('app').classList.add('hide_bg');
    this.load();
    this.$eventBus.on('promo-updated', this.load);

  },
  beforeUnmount() {
    document.getElementById('app').classList.remove('hide_bg');
    this.$eventBus.off('promo-updated', this.load);

  }
}
</script>

<style lang="scss">
.user {
  background: rgba(255, 255, 255, 0.8);
  padding: 10px 20px;
  border-radius: 10px;
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
  gap: 20px;
  align-items: flex-start;
  border: 1px solid var(--primary-text-color);

  & > div {
    //flex: 1;
  }
}

.controls {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;

  input[type="text"] {
    min-width: 300px;
  }

  input[type="date"] {
    min-width: 100px;
  }
}

.list-item {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 32px;
  margin-bottom: 5px
}

.adminTable {
  background: rgba(255, 255, 255, 0.8);
  padding: 10px 20px;
  border-radius: 10px;
  border-collapse: collapse;

  td, th {
    padding: 5px 10px;

    &:not(:last-child) {
      border-right: 1px solid #ddd;
    }
  }

  tbody tr {
    border-top: 1px solid #ddd;
  }
}
</style>

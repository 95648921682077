<template>
  <page-container class="game-page-container">
    <h1>{{ game.bg_colors.length > 1 ? '' : 'Онлайн-версия игры' }} «{{ game.title }}»</h1>
    <a v-if="game.offline_link" :href="game.offline_link" target="_blank"
       style="margin-bottom: 20px;margin-top:10px;display: block">Ссылка на офлайн версию</a>
    <div class="about">
      <div>
        <p v-html="game.about"></p>
        <div v-if="game.hasAccess" class="access">
          <Button @click="newGame" text="Новый сеанс" color="white"
                  text_color="var(--primary-text-color)"/>
          Оплачен тариф
          "{{ game.hasAccess.online ? (game.hasAccess.instructions ? 'Максимальный' : 'Продвинутый') : 'Базовый' }}"
          <template v-if="game.hasAccess.date_to">, доступ до
            {{ game.hasAccess.date_to.split('-').reverse().join('.') }}
          </template>
        </div>
        <div v-if="game.links && game.links.length" style="max-width: 400px;margin-top: 20px">
          <h2 style="margin-bottom: 20px">Прошлые сеансы</h2>
          <div v-for="link in game.links" :key="link.token"
               class="sessions">
            <a :href="`/game/${link.token}`" target="_blank">
              <Button text="Открыть"/>
            </a>
            <span style="margin-left: 20px">{{ $moment(link.datetime_update * 1000).format('D MMM YYYY HH:mm') }}</span>
          </div>
        </div>
      </div>
      <div class="right-block">
        <img class="image" :src="game.back"/>
        <viewer style="display: flex;gap: 5px;margin-bottom: 10px" v-if="game.screenshots.length"
                :images="game.screenshots">
          <img :style="`width: calc((100% - ${5*(game.screenshots.length-1)}px) / ${game.screenshots.length});`"
               style="border-radius: 5px;cursor: pointer;" v-for="src in game.screenshots" :key="src" :src="src">
        </viewer>
        <router-link v-if="+game.price" style="text-decoration: none;" :to="`/program/${game.id}`">
          <Button text="Программа (техническое описание)" color="white"
                  text_color="var(--primary-text-color)"/>
        </router-link>
        <a style="text-decoration: none;margin-top: 10px" v-if="game.form_file"
           :href="$server.url.split('/api/').join('')+`/forms/${encodeURI(game.form_file)}`" target="_blank">
          <Button style="margin-top: 10px" color="white" text_color="var(--primary-text-color)" text="Бланк игры"/>
        </a>
      </div>
    </div>
    <template v-if="+game.price">
      <h2 style="margin-top: 40px">Тарифы</h2>
      <div class="tariffs">
        <div v-for="(rate, i) in rates.filter(r=>+game[r.price])" :key="rate.title"
             style="display: flex;flex-direction: column;justify-content: space-between;">
          <div>
            <h3 style="margin-bottom: 10px">{{ rate.title }}</h3>
            <ul style="list-style-type: disc">
              <li v-for="feature in features.filter(f=>f.rates.includes(i) && (game.bg_colors.length<=1 ?f.games:f.field))"
                  :key="feature.title">{{
                  feature.title
                }}
              </li>
            </ul>
          </div>
          <div>
            <Button @click="$router.push('/cart')" style="margin-top: 20px"
                    v-if="$store.state.user.cart.items.find(item=>(item.type==='game' && item.item_id==game.id && item.tariff==rate.name))"
                    text="Перейти в корзину" color="white"
                    text_color="var(--primary-text-color)"/>
            <Button v-else style="margin-top: 20px;font-size: 16px;"
                    @click="$eventBus.emit('add-to-cart', {name: game.title, type:'game',item_id:game.id, tariff:rate.name})"
                    :text="`${game.hasAccess?'Продлить':'Купить'} на 1 год за ${game.hasAccess?game[rate.price+'_renewal']:game[rate.price]} ₽`"/>
            <p style="margin-top: 10px;text-align: center">Продление за <span
                style="text-decoration: line-through">{{ game[rate.price] }}</span> {{ game[rate.price + '_renewal'] }}
              ₽ (актуально только до окончания текущего доступа)</p>
          </div>

        </div>
      </div>
      <h2 style="margin-top: 40px">Комплектация</h2>
      <GameEquipment :game="game"/>
      <template v-if="game.bg_colors.length<=1">
        <h2 style="margin-top: 40px">Скидки</h2>
        <div class="tariffs">
          <div style="flex: 1" v-for="rate in rates.filter(r=>+game[r.price])" :key="rate.title">
            <p>Скидки при покупке сразу нескольких игр в тарифе "{{ rate.title }}":
            <ul v-if="game.discounts">
              <li v-for="(percent, games_count) in game.discounts" :key="games_count">{{ games_count }}
                игр{{ games_count >= 5 ? (games_count == 6 ? ' и более' : '') : 'ы' }}: скидка <b>-{{ percent }}%</b>
                <template v-if="games_count < 6">
                  =
                  <span
                      style="text-decoration: line-through;font-size: 18px;margin-right: 5px;">{{
                      game[rate.price] * games_count
                    }} ₽</span>
                  <span>{{ game[rate.price] * games_count * (100 - percent) / 100 }} ₽</span>
                </template>
              </li>
            </ul>
            </p>
          </div>
        </div>
      </template>
    </template>
    <div class="tariffs" v-else>
      <div v-for="(rate, i) in game.renewalOptions" :key="i"
           style="display: flex;flex-direction: column;justify-content: space-between;">
        <div>
          <Button @click="$router.push('/cart')" style="margin-top: 20px"
                  v-if="$store.state.user.cart.items.find(item=>(item.type==='game' && item.item_id==game.id && item.period==i))"
                  text="Перейти в корзину" color="white"
                  text_color="var(--primary-text-color)"/>
          <Button v-else style="margin-top: 20px;font-size: 16px;"
                  @click="$eventBus.emit('add-to-cart', {name: game.title, type:'game',item_id:game.id, tariff:'instructions', period: i})"
                  :text="`Продлить ${rate.title.toLowerCase()} за ${rate.price} ₽`"/>
        </div>

      </div>
    </div>

  </page-container>
</template>

<script>
import PageContainer from "@/components/PageContainer";
import GameEquipment from "@/pages/psycho/GameEquipment";

export default {
  name: "GameInfo",
  components: {GameEquipment, PageContainer},
  data() {
    return {
      rates: [
        {title: 'Базовый', price: 'price', name: 'default',},
        {title: 'Продвинутый', price: 'price2', name: 'online'},
        {title: 'Максимальный', price: 'price3', name: 'instructions'},
      ],
      features: [
        {title: 'красивый дизайн', games: 1, field: 1, rates: [0, 1, 2]},
        {title: 'удобное интуитивное управление', games: 1, field: 1, rates: [0, 1, 2]},
        {title: 'все колоды игры', games: 1, field: 0, rates: [0, 1, 2]},
        {title: 'универсальные и текстовые колоды', games: 0, field: 1, rates: [0, 1, 2]},
        {title: 'отличное качество изображений', games: 1, field: 1, rates: [0, 1, 2]},
        {title: '5 вариантов цвета поля', games: 0, field: 1, rates: [0, 1, 2]},
        {
          title: 'функции - увеличить карту, случайный выбор карт, перемешать колоду, перевернуть карты',
          games: 1,
          field: 1,
          rates: [0, 1, 2]
        },
        {title: '3d - кубики', games: 1, field: 0, rates: [0, 1, 2]},
        {title: 'Открывать карты и перемещать фишки может и ведущий, и игроки', rates: [1, 2], games: 1, field: 1},
        {
          title: 'Открывает карты и перемещает фишки ведущий, игроки видят игру при помощи функции "демонстрация экрана" в программах для организации видео-конференций (Zoom, Skype и т.п.)',
          rates: [0], games: 1, field: 1
        },
        {
          title: 'Само общение игроков может проходить в программах для организации видео-конференций (Zoom, Skype и т.п.)',
          rates: [1, 2], games: 1, field: 1
        },
        {title: 'Ведущий заходит на платформу через личный кабинет.', rates: [0], games: 1, field: 1},
        {
          title: 'Ведущий заходит на платформу через личный кабинет, далее он формирует ссылки на игру для игроков, по которым они получают временный доступ к материалам игры на время, ограниченное игрой',
          rates: [1, 2], games: 1, field: 1
        },
        {
          title: 'Онлайн-платформа прекрасно работает и с компьютера и с планшета',
          games: 1,
          field: 1,
          rates: [0, 1, 2]
        },
        {
          title: 'Участники игры могут пользоваться следующими функциями по полученной от ведущего ссылке на время, ограниченное игрой - доступ к полю, фишкам, колодам игры и кубикам.',
          games: 1,
          field: 0,
          rates: [1, 2]
        },
        {
          title: 'Игровое поле на экране представляет собой удобный аналог стола - ведущий и участники пользуются колодами в процессе игры; открывающиеся на поле карты, перемещения фишек видны на экране компьютера участникам и ведущему.',
          games: 1,
          field: 0,
          rates: [1, 2]
        },
        {
          title: 'Инструкция к игре в комплекте',
          games: 1,
          field: 0,
          rates: [2]
        },
      ],
      game: {links: [], screenshots: [], bg_colors: []}
    }
  },
  methods: {
    loadGame() {
      if (!this.$route.params.id) return;
      this.$server.request(`game/get/${this.$route.params.id}`, {}, (data) => {
        this.game = data.response
      })
    },
    newGame() {
      this.$server.request(`game/create/${this.game.id}`, {}, (data) => {
        location.href = `/game/${data.response}`;
        // this.$router.push(`/game/${data.response}`);
      }, (data) => {
        this.$eventBus.emit('show-error', data.error || 'Проверьте соединение с интернетом');
      });
    }
  },
  watch: {
    '$route.params.id'() {
      this.loadGame();
    }
  },
  mounted() {
    this.loadGame();
  }
}
</script>

<style scoped lang="scss">
.game-page-container {
  .about {
    display: flex;
    gap: 40px;
    justify-content: space-between;

    .right-block {
      float: right;
      width: 100%;
      max-width: 400px;
    }

    .image {
      min-width: 250px;
      border-radius: 10px;
      height: auto;
    }

    .button {
      //margin-top: 10px;
    }
  }
}

.page-container {
  max-width: calc(100% - 40px);
  width: 900px;
  margin: 20px auto;
}

.tariffs {
  display: flex;
  gap: 20px;
  padding: 20px;
  padding-left: 10px;
}

.access {
  display: flex;
  gap: 20px;
  margin-top: 20px;
  align-items: center;
}

.sessions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px
}

@media (max-width: 900px) {
  .game-page-container .about {
    flex-direction: column-reverse;
  }
  .page-container {
    width: calc(100% - 40px);
  }
  .tariffs {
    flex-direction: column;
  }
  .access, .sessions {
    gap: 20px;
    flex-direction: column;
  }
  .button {
    width: calc(100% - 20px);
  }
}
</style>

<template>
  <page-container class="game-page-container">
    <h1>{{ product.title }}</h1>
    <div class="about">
      <div>
        <p v-html="product.description"></p>
        <div v-if="+product.price" style="max-width: 300px">
          <Button @click="$router.push('/cart')" style="margin-top: 20px"
                  v-if="$store.state.user.cart.items.find(item=>(item.type==='product' && item.item_id==product.id))"
                  text="Перейти в корзину" color="white"
                  text_color="var(--primary-text-color)"/>
          <Button v-else style="margin-top: 20px;font-size: 16px;"
                  @click="$eventBus.emit('add-to-cart', {name: product.title, type:'product',item_id:product.id})"
                  :text="`Купить за ${product.price} ₽`"/>
        </div>
        <div>
          <div style="font-size: 18px;padding: 20px 0" v-for="key in Object.keys(attached).filter(k=>product['attached_'+k] && product['attached_'+k].length)" :key="key">
            <h3 style="margin-bottom: 20px">Подходящие {{attached[key]}}</h3>
            <div class="games-list" style="justify-content: flex-start;">
              <ProductCard :type="key" :size="200" v-for="attachedProduct in product['attached_'+key]" :key="attachedProduct.id+key" :product="attachedProduct"/>
            </div>
          </div>
        </div>
      </div>
      <div class="right-block">
        <img class="image" :src="product.image"/>
        <viewer style="display: flex;gap: 5px;margin-bottom: 10px" v-if="product.images.length"
                :images="product.images">
          <img :style="`width: calc((100% - ${5*(product.images.length-1)}px) / ${product.images.length});`"
               style="border-radius: 5px;cursor: pointer;" v-for="src in product.images" :key="src" :src="src">
        </viewer>
      </div>
    </div>
  </page-container>
</template>

<script>
import PageContainer from "@/components/PageContainer";
import GameEquipment from "@/pages/psycho/GameEquipment";
import ProductCard from "@/pages/psycho/ProductCard.vue";

export default {
  name: "Product",
  components: {ProductCard, GameEquipment, PageContainer},
  data() {
    return {
      attached: {courses: 'курсы',games: 'игры', products: 'товары'},
      product: {images: []},
    }
  },
  methods: {
    load() {
      if (!this.$route.params.id) return;
      this.$server.request(`product/get/${this.$route.params.id}`, {}, (data) => {
        this.product = data.response
      })
    },
  },
  watch: {
    '$route.params.id'() {
      this.load();
    }
  },
  mounted() {
    this.load();
  }
}
</script>

<style scoped lang="scss">
.game-page-container {
  .about {
    display: flex;
    gap: 40px;
    justify-content: space-between;

    .right-block {
      float: right;
      width: 100%;
      max-width: 400px;
    }

    .image {
      min-width: 250px;
      border-radius: 10px;
      height: auto;
    }

    .button {
      //margin-top: 10px;
    }
  }
}

.page-container {
  max-width: calc(100% - 40px);
  width: 900px;
  margin: 20px auto;
}

.tariffs {
  display: flex;
  gap: 20px;
  padding: 20px;
  padding-left: 10px;
}

.access {
  display: flex;
  gap: 20px;
  margin-top: 20px;
  align-items: center;
}

.sessions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px
}

@media (max-width: 900px) {
  .game-page-container .about {
    flex-direction: column-reverse;
  }
  .page-container {
    width: calc(100% - 40px);
  }
  .tariffs {
    flex-direction: column;
  }
  .access, .sessions {
    gap: 20px;
    flex-direction: column;
  }
  .button {
    width: calc(100% - 20px);
  }
}
</style>

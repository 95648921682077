<template>
  <div>
    <div class="images">
      <div v-for="(image, index) in images" :key="image" class="image"
           :style="`background-image:url(${image || '/img/empty_image.png'});${image?'background-size: cover;':''}`">
        <div @click="remove(index)" style="position: absolute;right: -10px;top: -10px;font-size: 10px">❌</div>
      </div>
    </div>
    <input accept="image/*" @change="upload" multiple ref="fileInput" type="file" style="display: none">
    <Button @click="$refs.fileInput.click()" style="margin-top: 10px;height: 16px" text="+ Добавить"/>
  </div>
</template>

<script>
import Button from "@/components/Button.vue";

export default {
  name: "ImagesInput",
  components: {Button},
  props: {
    modelValue: {},
  },
  data() {
    return {
      images: this.modelValue
    }
  },
  watch: {
    modelValue() {
      this.images = this.modelValue;
    }
  },
  methods: {
    remove(index) {
      if (!confirm('Удалить картинку?')) return;
      this.images.splice(index, 1);
      this.$emit('update:modelValue', this.images);
    },
    upload() {
      for (let i = 0; i < this.$refs.fileInput.files.length; ++i) {
        this.$server.request('auth/uploadFile', {file: this.$refs.fileInput.files[i]}, data => {
          this.images.push(data.response);
          this.$emit('update:modelValue', this.images);
        })
      }
    },
  },
}
</script>
<style scoped lang="scss">
.images {
  display: flex;
  gap: 10px;

  .image {
    position: relative;
    width: 40px;
    height: 40px;
  }
}
</style>
